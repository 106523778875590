import React, { useMemo } from "react";
import { type MonitorEvent } from "../../../types";

interface EventDetailProps {
  event: MonitorEvent;
}

const EventDetail: React.FC<EventDetailProps> = ({ event }) => {
  const durationSeconds = useMemo(() => {
    return (
      (new Date(event.endTime).getTime() -
        new Date(event.startTime).getTime()) /
      1000
    );
  }, [event]);

  const ttlSeconds = useMemo(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const secondsLeft = event.expirationTime - currentTimestamp;
    const daysLeft = secondsLeft / 86400;
    return Math.round(daysLeft);
  }, [event]);

  return (
    <div className="space-y-2 text-black dark:text-white">
      <div className="rounded border dark:border-none dark:bg-gray-800 h-8 p-1 px-2 overflow-hidden">
        <span className="text-gray-400 uppercase pr-2">Type</span>{" "}
        <span>{event.eventType}</span>
      </div>

      <div className="rounded border dark:border-none dark:bg-gray-800 h-8 p-1 px-2 overflow-hidden">
        <span className="text-gray-400 uppercase pr-2">Trigger Time</span>
        <span>{new Date(event.createdAt).toString()}</span>
      </div>

      <div className="rounded border dark:border-none dark:bg-gray-800 h-8 p-1 px-2 overflow-hidden">
        <span className="text-gray-400 uppercase pr-2">Duration</span>{" "}
        <span>{durationSeconds}s</span>
      </div>

      {event.expirationTime && (
        <div className="rounded border dark:border-none dark:bg-gray-800 h-8 p-1 px-2 overflow-hidden">
          <span className="text-red-300 uppercase pr-2">Expires</span>{" "}
          <span>{ttlSeconds} days</span>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
